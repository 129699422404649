<template>
  <div>
        <el-card >
          <el-button type="primary" @click="apiCall">QuickBooks CompanyInfoAPI Call</el-button>
          <el-divider></el-divider>
          <el-button type="primary" @click="connectQBO">Connect QBO</el-button>
        </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  name: 'qboConnected',
  setup() {
    const apiCall = () => {
      console.log('apiCall')
    }
    const connectQBO = () => {
      const path = process.env.VUE_APP_BASE + '/connectToQuickbooks'
      let parameters = 'location=1,width=800,height=650'
      parameters += ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2
      // Launch Popup
      window.open(path, 'connectPopup', parameters)
    }
    onMounted(() => {
    })
    return { apiCall, connectQBO }
  }
})
</script>
